/* Scoreboard.css */

/* Scoreboard.css */

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #000; /* Make the background black */
  background-image: url('../../public/spacepic.png');
  background-size: 150px 150px; /* Adjust size as necessary */
  background-repeat: repeat;
  animation: rotateTiles 10s infinite linear;
  overflow: hidden; /* Disable scrollbars */
}

/* Keyframes for rotating the background tiles */
@keyframes rotateTiles {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(2deg);
  }
  50% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(1deg);
  }
}

/* Other existing styles remain unchanged... */

.other-teams-text {
  font-size: 0.8em; /* Adjust this value to make the text smaller */
  color: #ff8c00; /* Ensure it matches the rest of the color scheme */
  margin-top: 20px;
}
.scoreboard-container {
  font-family: 'Press Start 2P', cursive; /* Retro gaming font */
  background-color: rgba(0, 0, 0, 0.9); /* Slightly transparent black to show the background pattern */
  color: #ff8c00; /* Orange color for text */
  text-align: center;
  padding: 20px;
  margin: 20px; /* Reduced margin to fit the screen */
  height: calc(100vh - 40px); /* Adjusted height to fit the screen */
  width: calc(100vw - 40px); /* Adjusted width to fit the screen */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align to the top */
  align-items: center;
  border: 30px solid teal; /* Outer border */
  border-radius: 35px; /* Rounded corners */
  box-sizing: border-box;
  position: relative;
}

.scoreboard-container:before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 10px solid pink; /* Middle border */
  border-radius: 25px; /* Rounded corners for middle border */
  pointer-events: none;
  box-sizing: border-box;
}

.scoreboard-container:after {
  content: '';
  position: absolute;
  top: -30px;
  left: -30px;
  right: -30px;
  bottom: -30px;
  border: 10px solid purple; /* Inner border */
  border-radius: 35px; /* Rounded corners for inner border */
  pointer-events: none;
  box-sizing: border-box;
}

.scoreboard-title {
  font-size: 5em;
  margin-bottom: 20px;
  background: linear-gradient(to right, red, orange, purple);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  position: relative;
}

.scoreboard-table {
  width: 80%;
  border-collapse: collapse;
  margin: 0 auto;
}

/* Adjust the border color for the table */
.scoreboard-table th,
.scoreboard-table td {
  border: 2px solid #ff6600; /* Less intrusive orange for table borders */
  padding: 10px;
}

/* Set the background color for team scores to black */
.scoreboard-table td {
  background-color: #000; /* Black background for team scores */
  color: #ff8c00; /* Keep the orange color for text */
}

/* Keep the alternating row colors for better readability */
.scoreboard-table tr:nth-child(even) {
  background-color: #111; /* Darker black for even rows */
}

/* Keep headers as they are */
.scoreboard-table th {
  background-color: #333;
  color: #ff8c00;
}

/* First place - Gold */
.first-place td {
  color: gold;
}

/* Second place - Silver */
.second-place td {
  color: silver;
}

/* Third place - Bronze */
.third-place td {
  color: #cd7f32; /* Bronze */
}

/* Non-top-three teams - White text */
.scoreboard-table tr:not(.first-place):not(.second-place):not(.third-place) td {
  color: white;
}


.countdown-timer {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1000;
}
